import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/app.sass';
import Routes from './Routes';
import reduxStore from './store';

const App = () => (
  <Provider store={reduxStore}>
    <div className="App">
      <ToastContainer id="forToast" hideProgressBar />
      <Routes />
    </div>
  </Provider>
);

export default App;
