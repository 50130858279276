import React from 'react';
import PropTypes from 'prop-types';

import AppHeader from '../../components/AppHeader/AppHeaderContainer';
import AppFooter from '../../components/AppFooter/AppFooterContainer';

const MainTemplate = ({ children }) => (
  <React.Fragment>
    <AppHeader />
    {children}
    <AppFooter />
  </React.Fragment>
);

MainTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

MainTemplate.defaultProps = {
  children: <div />,
};

export default MainTemplate;
