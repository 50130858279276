import React from 'react';

import AnswerSurveyContainer from '../../modules/AnswerSurvey/components/AnswerSurveyContainer';
import MainTemplate from '../../shared/templates/MainTemplate/MainTemplateContainer';

const AnswerSurvey = () => (
  <MainTemplate>
    <AnswerSurveyContainer />
  </MainTemplate>
);

export default AnswerSurvey;
