import React from 'react';

import DashboardContainer from '../../modules/dashboard/DashboardContainer';
import MainTemplate from '../../shared/templates/MainTemplate/MainTemplateContainer';

const Dashboard = () => (
  <MainTemplate>
    <DashboardContainer />
  </MainTemplate>

);

export default Dashboard;
