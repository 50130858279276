import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import Dashboard from './Dashboard';
import { BASE_URL } from '../../config';

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyCode: '',
    };
    this.onHandlerSubmit = this.onHandlerSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.keyUpHandler = this.keyUpHandler.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  onHandlerSubmit() {
    const { surveyCode } = this.state;
    const { history } = this.props;
    if (surveyCode) {
      axios
        .post(`${BASE_URL}/fetch_survey`, {
          surveyCode,
        })
        .then((res) => {
          const resData = res.data;
          if (resData.error) {
            toast.error(resData.message);
          } else {
            history.push(`${surveyCode}`);
          }
        });
    } else {
      toast.warning('8-digit PIN required from SurveyStud.com');
    }
  }

  keyUpHandler(event) {
    if (event.keyCode === 13) { // Here... keyCode 13 is used for ENTER KEY
      this.onHandlerSubmit();
    }
  }

  handleChange(event) {
    const inputData = event.target;
    this.setState({ surveyCode: inputData.value });
  }

  render() {
    return (
      <Dashboard
        onHandlerSubmit={this.onHandlerSubmit}
        handleChange={this.handleChange}
        keyUpHandler={this.keyUpHandler}
      />
    );
  }
}

DashboardContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(DashboardContainer);
