import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const Loader = ({ isLoading }) => isLoading && (
<section className="loader-section">
  <Spinner animation="border" role="status">
    <span className="sr-only">Loading...</span>
  </Spinner>
</section>
);

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

Loader.defaultProps = {
  isLoading: false,
};

export default Loader;
