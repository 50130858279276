import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'react-bootstrap';

import noop from '../../utils';
import logo from '../../assets/images/logo-new.png';

const Dashboard = ({ onHandlerSubmit, handleChange, keyUpHandler }) => (
  <Container
    style={{ height: 'calc(100vh - 65px)' }}
    className="d-flex justify-content-center align-items-center"
  >
    <div
      className="dashboard-card d-flex flex-column"
    >
      <img src={logo} alt="logo" />
      <input
        type="text"
        name="survey_code"
        className="inpute-field"
        placeholder="Enter PIN"
        onChange={handleChange}
        onKeyUp={keyUpHandler}
      />
      <Button
        variant="dark"
        size="lg"
        onClick={onHandlerSubmit}
      >
        ENTER
      </Button>
    </div>
  </Container>
);

Dashboard.propTypes = {
  onHandlerSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  keyUpHandler: PropTypes.func,
};

Dashboard.defaultProps = {
  onHandlerSubmit: noop,
  handleChange: noop,
  keyUpHandler: noop,
};

export default Dashboard;
