import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';

import logo from '../../assets/images/logo-new.png';
import MainTemplate from '../templates/MainTemplate/MainTemplateContainer';


const ThanksFeedback = () => (
  <MainTemplate>
    <section className="section__thanks">
      <Container className="d-flex justify-content-center align-items-center text-center h-100">
        <Card className="card__container">
          <Card.Body>
            <Link to="/"><img className="cursor-pointer" src={logo} alt="logo" /></Link>
            <div className="pb-3 h-100 d-flex justify-content-center flex-column">
              <Card.Text>
                {/* <p>Thanks for sharing your feedback.</p> */}
                
                {' Thanks For Your Feedback '}
                
                <a href="https://www.surveystud.com/" rel="noopener noreferrer" target="_blank"> SurveyStud.com </a>
                
                {' '}
               
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </section>
  </MainTemplate>
);

export default ThanksFeedback;
