import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';

const NotFound = () => (
  <section className="not__found">
    <Container className="d-flex justify-content-center align-items-center text-center h-100">
      <Card className="card__container">
        <Card.Body>
          <Card.Title>404</Card.Title>
          <Card.Text>
            This is not the page you are looking for
          </Card.Text>
          <Link to="/">Go To Home Page</Link>
        </Card.Body>
      </Card>
    </Container>
  </section>
);
export default NotFound;
