import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { BASE_URL, ROOT_URL } from "../../../config";
import CustomLoader from "../../../shared/components/loader";
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import logo from "../../../assets/images/logo-new.png";
import MainTemplate from "../../../shared/templates/MainTemplate/MainTemplate";

const HorizontalAnswers = Array.from({ length: 10 }).map(
  (_, index) => index + 1
);

let newValue = [];
let isRegistrationForm = false;
let isGradeSet = false;
class AnswerSurveyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyFetchData: {},
      answers: [],
      checkAllFillAnswer: [],
      selectedQuestions: [],
      loading: false,
      userId: "",
      surveyTitle: "",
      feedback: "",
      textFeedback: [],
      surveyLogo: "",
    };
    this.checkedValue = this.checkedValue.bind(this);
    this.onAnswersubmit = this.onAnswersubmit.bind(this);
    this.handleTextInput = this.handleTextInput.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    window.scroll(0, 0);
    const surveyCode = match.params.id;
    this.setState({ loading: true });
    axios
      .post(`${BASE_URL}/fetch_survey`, {
        surveyCode,
      })
      .then((res) => {
        const resData = res.data;
        this.setState({
          surveyFetchData: resData,
          userId: resData.surveyData?.user_id || null,
          surveyTitle: resData.surveyData?.survey_title || "",
          surveyLogo: resData.surveyData?.survey_logo || "",
        });

        this.setState({ loading: false });
        if (resData.error) {
          toast.error(resData.message);
        }
      })
      .catch(() => this.setState({ loading: false }));
  }

  onAnswersubmit() {
    let errorIndex = "0";
    const { surveyFetchData, answers, feedback, textFeedback } = this.state;
    const surveyData = surveyFetchData.questionsData;
    const { match } = this.props;
    const { history } = this.props;
    const surveyCode = match.params.id;
    if (!surveyData) {
      return null;
    }
    console.log("feedback", feedback, "textFeedback", textFeedback);

    if (newValue.length !== 0) {
      errorIndex = newValue.findIndex((item) => typeof item === "undefined");
    }
    // Check for registration if grade is empty, allow to submit
    const registerCheck =
      isRegistrationForm && !isGradeSet && answers.length === 4;
    const groupCheck = textFeedback && feedback;
    if (errorIndex === -1 || registerCheck || groupCheck) {
      if (Array.from(surveyData).length === answers.length || registerCheck) {
        if (!feedback) {
          toast.warn("Please write a feedback");
          return;
        }
        this.setState({ loading: true });
        const allAnswers = answers.sort((a, b) => a.questionId - b.questionId);
        const { userId, surveyTitle } = this.state;
        let escapedFeedback = feedback
          .replace(/\\/g, "\\\\")
          .replace(/"/g, '\\"')
          .replace(/'/g, "\\'");
        let concatenatedFeedback = escapedFeedback;

        if (textFeedback && textFeedback.length > 0) {
          textFeedback.forEach((item) => {
            let escapedItem = JSON.stringify(item)
              .replace(/\\/g, "\\\\")
              .replace(/"/g, '\\"')
              .replace(/'/g, "\\'");
            concatenatedFeedback += ` ${escapedItem}`;
          });
        }

        axios
          .post(`${BASE_URL}/survey_vote`, {
            allAnswers,
            userId,
            surveyTitle,
            surveyCode,
            feedback: concatenatedFeedback,
          })
          .then((res) => {
            const resData = res.data;
            if (resData.error) {
              toast.error(resData.message);
            } else {
              history.push("/");
            }
            history.push(`/feed/thanks`);
          });
      }
    } else {
      toast.warn("You have unanswered questions");
    }
  }

  checkedValue(quevalue, questionId, queIndex) {
    const { answers, surveyFetchData, checkAllFillAnswer, selectedQuestions } =
      this.state;
    const TotalLength = Object.keys(surveyFetchData.questionsData);
    newValue = TotalLength.map((item, i) => checkAllFillAnswer[i] || undefined);
    newValue[queIndex] = quevalue;
    this.setState({
      checkAllFillAnswer: newValue,
      selectedQuestions: [...selectedQuestions, questionId],
    });

    const newAns = { questionId, optionVote: quevalue };
    let _ans = answers || [];
    if (answers.findIndex((item) => item.questionId === questionId) !== -1) {
      _ans = _ans.map((item) =>
        item.questionId === newAns.questionId ? newAns : item
      );
    } else {
      _ans = [..._ans, newAns];
    }
    this.setState({
      answers: _ans,
    });
  }

  handleTextInput(value, questionId, questionTitle, queIndex) {
    const { answers, checkAllFillAnswer, feedback } = this.state;
    const newAnswers = answers || [];
    newValue = checkAllFillAnswer.length
      ? [...checkAllFillAnswer]
      : Array(this.state.surveyFetchData.questionsData.length - 1).fill(
          undefined
        );
    const index = newAnswers.findIndex(
      (item) => item.questionId === questionId
    );
    if (index !== -1) {
      newAnswers[index] = {
        ...newAnswers[index],
        optionVote: value,
        questionTitle,
      };
    } else {
      newAnswers.push({ questionId, optionVote: value });
    }
    let updatedFeedback = { [questionTitle]: value };
    if (feedback) {
      updatedFeedback = {
        ...JSON.parse(feedback),
        [questionTitle]: value,
      };
    }
    if (questionTitle === "Grade/Rank") {
      isGradeSet = true;
    }

    if (questionTitle !== "Grade/Rank" || value.trim() !== "") {
      newValue[queIndex] = true;
    } else {
      newValue[queIndex] = false;
    }

    this.setState({
      answers: newAnswers,
      feedback: JSON.stringify(updatedFeedback),
      checkAllFillAnswer: newValue,
    });
  }
  onChangeInput(
    value,
    questionId,
    questionTitle,
    queIndex,
    questionType = false
  ) {
    const { answers, checkAllFillAnswer, surveyFetchData, textFeedback } =
      this.state;
    const newAnswers = answers || [];
    const newValue = checkAllFillAnswer.length
      ? [...checkAllFillAnswer]
      : Array(surveyFetchData.questionsData.length).fill(undefined);

    const index = newAnswers.findIndex(
      (item) => item.questionId === questionId
    );
    if (index !== -1) {
      newAnswers[index] = {
        ...newAnswers[index],
        optionVote: value,
        questionTitle,
      };
    } else {
      newAnswers.push({ questionId, optionVote: value });
    }

    let updatedFeedback = { [questionTitle]: value };
    let updatedTextFeedback = textFeedback || [];

    if (questionTitle !== "Grade/Rank/Mr/Mrs/Dr" || value.trim() !== "") {
      newValue[queIndex] = true;
    } else {
      newValue[queIndex] = false;
    }

    const existingIndex = updatedTextFeedback.findIndex(
      (item) => Object.keys(item)[0] === questionTitle
    );
    if (existingIndex !== -1) {
      updatedTextFeedback[existingIndex] = updatedFeedback;
    } else {
      updatedTextFeedback.push(updatedFeedback);
    }

    if (questionType) {
      this.setState({
        answers: newAnswers,
        textFeedback: updatedTextFeedback,
        checkAllFillAnswer: newValue,
      });
    } else {
      this.setState({
        answers: newAnswers,
        feedback: JSON.stringify(updatedFeedback),
        textFeedback: updatedTextFeedback,
      });
    }
  }

  render() {
    const { surveyFetchData, selectedQuestions, loading } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          <CustomLoader isLoading={loading} />
        </React.Fragment>
      );
    }

    const surveyData = surveyFetchData.questionsData;
    // Check if it's registration form
    if (
      surveyFetchData &&
      surveyFetchData.questionsData &&
      surveyFetchData?.questionsData[0]?.questions.question_type ===
        "registration-form"
    ) {
      isRegistrationForm = true;
    }
    if (!surveyFetchData || !surveyData || surveyFetchData.error === true) {
      return (
        <MainTemplate>
          <section className="section__thanks">
            <Container className="d-flex justify-content-center align-items-center text-center h-100">
              <Card className="card__container">
                <Card.Body>
                  <Link to="/">
                    <img className="cursor-pointer" src={logo} alt="logo" />
                  </Link>
                  <div className="pb-3 h-100 d-flex justify-content-center flex-column">
                    <Card.Text>
                      {" Apologies, but the survey has expired! "}
                      <a
                        href="https://www.surveystud.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        SurveyStud.com{" "}
                      </a>{" "}
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Container>
          </section>
        </MainTemplate>
      );
    }

    return (
      <React.Fragment>
        <React.Fragment>
          <Container className="form-container padding_remove_mobile">
            <div
              style={{ textAlign: "center" }}
              className="survey-logo-container"
            >
              {this.state.surveyLogo ? (
                <img src={`${ROOT_URL}${this.state.surveyLogo}`} alt="logo" />
              ) : (
                <img src={logo} alt="logo" />
              )}
            </div>
            <div className="p-2 mb-2 mt-2">
              <h5 className="m-0">
                <b>
                  {isRegistrationForm ? "Title: " : "Survey:"}{" "}
                  {surveyFetchData.surveyData.survey_title}
                </b>
              </h5>
            </div>
            <hr />
            {surveyData.map((data, index) => (
              <div key={index}>
                {index > 0 && index % 4 == 0 && !isRegistrationForm && (
                  <div
                    style={{ textAlign: "center" }}
                    className="survey-logo-container"
                  >
                    {this.state.surveyLogo ? (
                      <img
                        src={`${ROOT_URL}${this.state.surveyLogo}`}
                        alt="logo"
                      />
                    ) : (
                      <img src={logo} alt="logo" />
                    )}
                  </div>
                )}
                <table
                  className="survey-quetions-table table table-borderless"
                  borderless="true"
                  responsive="true"
                >
                  <tbody>
                    <tr>
                      <td>
                        <p className="font-mobile">
                          <b>
                            {`${index + 1}.`}&nbsp;
                            {data.questions.question_title}
                          </b>
                        </p>
                        <input
                          type="hidden"
                          name="option_id"
                          value={data.questions.question_id}
                        />
                      </td>
                    </tr>
                    {data.questions.question_type === "horizontal-multi" ? (
                      <tr>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {HorizontalAnswers.map((answer) => (
                              <div
                                key={answer}
                                style={{
                                  flexGrow: 1,
                                  textAlign: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label htmlFor={`anwser_${index}`}>
                                  {answer}
                                </label>
                                <input
                                  id={`anwser_${index}`}
                                  type="radio"
                                  name={index}
                                  value={`option${answer}_votes`}
                                  onChange={(e) =>
                                    this.checkedValue(
                                      e.target.value,
                                      data.questions.question_id,
                                      index
                                    )
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ) : data.questions.question_type === "registration-form" ? (
                      <input
                        value={
                          this.state.answers.find(
                            (ans) =>
                              ans.questionId === data.questions.question_id
                          )?.optionVote || ""
                        }
                        onChange={(e) =>
                          this.handleTextInput(
                            e.target.value,
                            data.questions.question_id,
                            data.questions.question_title,
                            index
                          )
                        }
                        name={`question_${data.questions.question_id}`}
                        type="text"
                        className="w-100 rounded"
                        maxLength="40"
                        style={{
                          height: "50px",
                          marginBottom: "-10px",
                          marginTop: "-10px",
                        }}
                      />
                    ) : data.questions.question_type === "text" ? (
                      <tr>
                        <td>
                          <textarea
                            value={
                              this.state.answers.find(
                                (ans) =>
                                  ans.questionId === data.questions.question_id
                              )?.optionVote || ""
                            }
                            onChange={(e) =>
                              this.onChangeInput(
                                e.target.value,
                                data.questions.question_id,
                                data.questions.question_title,
                                index,
                                data.questions.question_type === "text"
                              )
                            }
                            name={`question_${data.questions.question_id}`}
                            type="text"
                            rows="4"
                            className="w-100 rounded feedback-field"
                            maxLength="230"
                          />
                        </td>
                      </tr>
                    ) : (
                      Object.keys(data.options).map((key, qIndex) => {
                        if (!data.options[key]) {
                          return null;
                        }
                        return (
                          <tr key={key}>
                            <td>
                              <label
                                htmlFor={`anwser_${key}_${index}`}
                                className="radio"
                              >
                                <span className="font-mobile">
                                  {data.options[key]}
                                </span>
                                <input
                                  id={`anwser_${key}_${index}`}
                                  type="radio"
                                  name={index}
                                  value={key}
                                  onChange={(e) =>
                                    this.checkedValue(
                                      e.target.value,
                                      data.questions.question_id,
                                      index
                                    )
                                  }
                                />
                                <span className="checkround" />
                              </label>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <br />
              </div>
            ))}
            {surveyData[0].questions.question_type === "registration-form" ? (
              <></>
            ) : (
              <>
                <Row>
                  <Col>
                    <label className=" pr-2">
                      <b>Provide feedback</b>
                    </label>
                    <textarea
                      value={this.state.feedback}
                      onChange={(e) => {
                        this.setState({
                          feedback: e.target.value,
                        });
                      }}
                      name="feedback"
                      type="text"
                      rows="4"
                      className="w-100 rounded feedback-field"
                      maxLength="230"
                    />
                    <span
                      className="align-items-center d-flex justify-content-center"
                      style={{ fontSize: "0.7rem", width: "40px" }}
                    >
                      ({230 - this.state.feedback.length})
                    </span>
                  </Col>
                </Row>
              </>
            )}
            <div className="mt-4" style={{ textAlign: "center" }}>
              <Button
                type="button"
                name="submit"
                variant="outline-secondary"
                className="p-2"
                onClick={this.onAnswersubmit}
              >
                Submit
              </Button>
              <br />
              <div
                style={{ textAlign: "center" }}
                className="survey-logo-container pb-5 pt-5"
              >
                {this.state.surveyLogo ? (
                  <img src={`${ROOT_URL}${this.state.surveyLogo}`} alt="logo" />
                ) : (
                  <img src={logo} alt="logo" />
                )}
              </div>
            </div>
          </Container>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

AnswerSurveyContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any),
};

AnswerSurveyContainer.defaultProps = {
  match: {},
};

export default withRouter(AnswerSurveyContainer);
