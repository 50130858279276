import React, { Fragment } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AnswerSurvey from './scenes/AnswerSurvey/AnswerSurvey';
import Dashboard from './scenes/dashboard/Dashboard';
// import Feedback from './scenes/Feedback/Feedback';

import NotFound from './shared/components/NotFound';
import ThanksFeedback from './shared/components/ThanksFeedback';


const Routes = () => (
  <BrowserRouter>
    <Fragment>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/:id" component={AnswerSurvey} />
        {/* <Route exact path="/:surveyCode/feedback" component={Feedback} /> */}
        <Route exact path="/feed/thanks" component={ThanksFeedback} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Fragment>
  </BrowserRouter>
);

export default Routes;
